/* footer */
footer {
    font-size: 16px;
    color: #cccccc;
    ul {
        @include ul-default;
    }
    a {
        color: #cccccc;
        &:focus {
            color: #cccccc;
        }
        &:hover {
            color: #fff;
        }
    }
    .nav {
        height: 75px;
        line-height: 1;
        > ul {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            > li {
                > a {
                    color: #fff;
                    text-transform: uppercase;
                    padding: 10px 0;
                    border-bottom: 2px solid transparent;
                    &:hover {
                        border-bottom: 2px solid #fff;
                    }
                }
                &.active a {
                    border-bottom: 2px solid #fff;
                }
            }
        }
    }
    h4 {
        color: #ffffff;
        margin: 0 0 20px 0;
    }
    .section--img {
        padding: 78px 0px 0px;
        > .container {
            display: flex;
            justify-content: space-between;
        }
    }
    .footer__col {
        ul li {
            margin-bottom: 20px;
        }
        a {
            display: table;
        }
    }
    .down {
        height: 65px;
        font-size: 14px;
        border-top: 1px solid #7f8080;
        margin-top: 90px;
        .container {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
        }
        p {
            margin: 0;
        }
        .artmedia {
            display: flex;
            align-items: center;
        }
    }
    @media screen and (max-width: 1024px) {
        .nav {
            > ul > li {
                > a {
                    font-size: 14px;
                    padding: 6px 0px;
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        .footer__logo {
            display: table;
            margin: 0 auto 25px;
        }
        h4 {
            margin-bottom: 15px;
        }
        .section--img {
            padding: 40px 0 0 0;
            > .container {
                display: block;
            }
        }
        .footer__col {
            width: 100%;
            margin-bottom: 25px;
        }
        .down {
            margin-top: 50px;
        }
    }
    @media screen and (max-width: 600px) {
        .nav {
            display: block;
            height: auto;
            padding: 20px 0 10px;
            > ul {
                flex-direction: column;
                > li {
                    margin-bottom: 10px;
                    > a {
                        display: block;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .section--img {
            padding: 25px 0 0 0;
        }
        .down {
            height: auto;
            text-align: center;
            padding: 10px 0;
            margin-top: 30px;
            .container {
                flex-direction: column;
                text-align: center;
            }
            .artmedia {
                margin-top: 10px;
            }
        }
    }
}
/* footer */