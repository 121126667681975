/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 13, 2019 */
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/open-sans-webfont.eot');
    src: url('../fonts/open-sans-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans-webfont.woff2') format('woff2'),
         url('../fonts/open-sans-webfont.woff') format('woff'),
         url('../fonts/open-sans-webfont.ttf') format('truetype'),
         url('../fonts/open-sans-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/open-sans-bold-webfont.eot');
    src: url('../fonts/open-sans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans-bold-webfont.woff2') format('woff2'),
         url('../fonts/open-sans-bold-webfont.woff') format('woff'),
         url('../fonts/open-sans-bold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansitalic';
    src: url('../fonts/opensans-italic-webfont.eot');
    src: url('../fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-italic-webfont.woff') format('woff'),
         url('../fonts/opensans-italic-webfont.ttf') format('truetype'),
         url('../fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'RubikMedium';
    src: url('../fonts/RubikMedium.eot');
    src: url('../fonts/RubikMedium.eot') format('embedded-opentype'),
         url('../fonts/RubikMedium.woff2') format('woff2'),
         url('../fonts/RubikMedium.woff') format('woff'),
         url('../fonts/RubikMedium.ttf') format('truetype'),
         url('../fonts/RubikMedium.svg#RubikMedium') format('svg');
}
@font-face {
    font-family: 'RubikBold';
    src: url('../fonts/RubikBold.eot');
    src: url('../fonts/RubikBold.eot') format('embedded-opentype'),
         url('../fonts/RubikBold.woff2') format('woff2'),
         url('../fonts/RubikBold.woff') format('woff'),
         url('../fonts/RubikBold.ttf') format('truetype'),
         url('../fonts/RubikBold.svg#RubikBold') format('svg');
}
@font-face {
    font-family: 'rubikregular';
    src: url('../fonts/rubik-regular-webfont.eot');
    src: url('../fonts/rubik-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/rubik-regular-webfont.woff2') format('woff2'),
         url('../fonts/rubik-regular-webfont.woff') format('woff'),
         url('../fonts/rubik-regular-webfont.ttf') format('truetype'),
         url('../fonts/rubik-regular-webfont.svg#rubikregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanssemibold';
    src: url('../fonts/opensans-semibold-webfont.eot');
    src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}


$regular: 'open_sansregular';
$bold: 'open_sansbold';
$boldS: 'open_sanssemibold';
$light: 'open_sanslight';
$italic: 'open_sansitalic';
$mediumR: 'RubikMedium';
$boldR: 'RubikBold';
$regularR: 'rubikregular';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}
* {
    box-sizing: border-box;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    color: #292929;
	margin: 0;
    overflow-x: hidden;
    &.load {
        a {
            transition: 300ms;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: 100px;
    }
    @media screen and (max-width: 768px) {
        display: block;
    }
}

h1 {
	font-family: $boldR;
	font-size: 38px;
	text-transform: uppercase;
	text-align: center;
	color: #292929;
    text-transform: uppercase;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 38px;
    }
	@media screen and (max-width: 1280px) {
		font-size: 32px;
        margin-bottom: 30px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 480px) {
        font-size: 22px;
        a {
            font-size: 22px;
        }
    }
}
h2 {
	font-family: $boldR;
	font-size: 36px;
	color: #292929;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 40px;
	a {
        font-size: 36px;
	}
	@media screen and (max-width: 1280px) {
		font-size: 30px;
        margin-bottom: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
}
h3 {
	font-family: $mediumR;
	font-size: 24px;
    font-weight: normal;
	color: #292929;
    text-transform: uppercase;
	@media screen and (max-width: 1280px) {
		font-size: 22px;
	}
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h4 {
	font-family: $mediumR;
	font-size: 20px;
    font-weight: normal;
	color: #292929;
    text-transform: uppercase;
    @media screen and (max-width: 1280px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
     @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
h5 {
	font-family: $mediumR;
	font-size: 18px;
	color: #292929;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
h6 {
	font-family: $mediumR;
	font-size: 16px;
	text-transform: uppercase;
	color: #292929;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
}
p {
    margin: 0 0 15px 0;
}
a {
    color: #292929;
	text-decoration: none;
	&:focus {
		color: #292929;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #509002;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="submit"] {
	border-radius: 3px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
        min-height: 50px;
        background-color: #fff;
        border-radius: 3px;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
        color: #333333;
        border: 1px solid #e6e6e6;
		padding: 15px 30px;
        margin-bottom: 15px;
        box-shadow: none;
        &::placeholder {
            color: #949494;
        }
	}
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 40px;
            font-size: 14px;
            padding: 10px 15px;
        }
    }
}
.main {
	.container {
		padding-top: 95px;
		padding-bottom: 95px;
		@media screen and (max-width: 1199px) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
        @media screen and (max-width: 768px) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
	}
}
.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    @media screen and (max-width: 1170px) {
        width: 100%;
    }
}
.close {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 8px;
    top: 8px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 22px;
        height: 2px;
        background-color: #508f00;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
    height: 50px;
	width: auto!important;
	background-color: #508f00!important;
	font-family: $regular!important;
	font-size: 16px;
	color: #fff!important;
    text-transform: uppercase;
	border-radius: 0px;
	border: 2px solid #508f00;
	padding: 12px 40px 11px!important;
    border-radius: 4px;
    cursor: pointer;
	transition: 300ms;
    outline: none;
	&:focus, &:active {
		background-color: #508f00!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #292929!important;
	}	
    @media screen and (max-width: 1024px) {
        height: 40px;
        font-size: 14px;
        padding: 8px 20px 8px!important;
    }
}

.circle--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 25px;
		&:before {
			content: '';
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background-color: #508f00;
			position: absolute;
			left: 0px;
			top: 9px;
		}
	}
    @media screen and (max-width: 1024px) {
        li {
            margin-bottom: 20px;
            &:before {
                top: 8px;   
            }
        }
    }
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #508f00;
		position: absolute;
		top: 4px;
		left: 0;
	}
    @media screen and (max-width: 1024px) {
        &:after {
            top: 3px;
        }
    }
    @media screen and (max-width: 768px) {
        top: 1px;
    }
}
.col--location {
    &:after {
        content: '\f041';
        top: 4px;
    }
    @media screen and (max-width: 1024px) {
        &:after {
            top: 3px;
        }
    }
    @media screen and (max-width: 768px) {
        &:after {
            top: 1px;
        }
    }
}
.col--phone:after {
	content: '\f095';
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 12px;
        top: 5px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @media screen and (max-width: 768px) {
        &:after {
            top: 3px;
        }
    }
}

.rad {
    border-radius: 8px;
    overflow: hidden;
}

.gr {
    background: linear-gradient(to right, #4b7e06, #a9be56);
    box-shadow: inset 2px 0px 20px rgba(0,0,0,0.3);
}

/* header */
header {
    height: 133px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    line-height: 1;
    z-index: 9;
    &.header {
        background-color: #ffffff;
        position: fixed;
        box-shadow: 0px 0px 20px #dadcdf;
        .nav > ul > li {
            &:nth-of-type(-n+3) {
                > a {
                    color: #292929;
                    &:hover {
                        border-bottom: 2px solid #292929;
                    }
                }
                &.active > a {
                    color: #292929;
                    border-bottom: 2px solid #292929;
                }
            }
        }
    }
    ul {
        @include ul-default;
    }
    .container {
        height: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        padding-right: 125px;
    }

	/* header logo */
	.logo {
        width: 120px;
        height: 120px;
        margin-right: 50px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
	/* header logo */

	/* header nav */
	.nav {
        width: 100%;
        > ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > li {
                > a {
                    text-transform: uppercase;
                    line-height: 1;
                    padding: 12px 0;
                    border-bottom: 2px solid transparent;
                    &:hover {
                        color: #292929;
                        border-bottom: 2px solid #292929;
                    }
                }
                &.active > a {
                    border-bottom: 2px solid #292929;
                }
            }
        }
    }
	/* header nav */

	/* header langs */
	.langs {
        font-size: 18px;
        text-transform: uppercase;
        position: absolute;
        top: 49%;
        right: 15px;
        padding-right: 17px;
        transform: translateY(-50%);
        cursor: pointer;
        &:after {
            content: '\f107';
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #292929;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        ul {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: 300ms;
            background-color: rgba(41, 41, 41, 0.9);
            border-radius: 8px;
            padding: 8px 12px;
            z-index: 1;
            li {
                margin: 3px 0;
                a {
                    color: #fff;
                    &:hover {
                        color: #509002;
                    }
                }
                &.active {
                    a {
                        color: #509002;
                    }
                }
            }
        }
        &.open {
            ul {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
	/* header langs */
    @media screen and (min-width: 1025px) {
        .nav {
            > ul > li {
                &:nth-of-type(-n+3) {
                    > a {
                        color: #fff;
                        &:hover {
                            border-bottom: 2px solid #fff;
                        }
                    }
                    &.active > a {
                        color: #fff;
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        height: 100px;
        position: fixed;
        background-color: #fff;
        box-shadow: 0 0 20px #dadcdf;
        .container {
            padding-right: 100px;
        }
        .logo {
            width: 90px;
            height: 90px;
            margin-right: 25px;
        }
        .nav {
            > ul > li {
                > a {
                    font-size: 14px;
                    padding: 8px 0;
                }
            }
        }
        .langs {
            &:after {
                top: 55%;
            }
            .langs__title {
                font-size: 14px;
            }
            ul {
                padding: 6px 10px;
                li a {
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .container {
            justify-content: center;
            padding: 10px 15px;
        }
        .logo {
            display: block;
            height: 100%;
            margin: 0 auto;
        }
        .nav--btn {
            width: 35px;
            height: 30px;
            position: absolute;
            left: 10px;
            top: 50%;
            padding: 0;
            margin: 0;
            outline: none;
            float: none;
            transform: translateY(-50%);
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 85%;
                height: 3px;
                margin: 0 auto;
                background-color: #508f00;
                transition: 350ms;
                &:nth-of-type(1) {
                    top: 7px;
                }
                &:nth-of-type(2) {
                    top: 14px;
                }
                &:nth-of-type(3) {
                    top: 21px;
                }
            }
            &.open {
                span {
                    &:nth-of-type(1) {
                        top: 14px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        top: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .nav {
            display: none;
            max-height: calc(100% - 90px);
            background: linear-gradient(to right,#4b7e06,#a9be56);
            position: fixed;
            left: 0;
            top: 100px;
            right: 0;
            padding: 15px 15px;
            overflow: auto;
            > ul {
                display: block;
                > li {
                    display: block;
                    margin-bottom: 15px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    > a {
                        display: table;
                        color: #fff;
                        padding-top: 0;
                        &:hover {
                            color: #fff;
                            border-bottom: 2px solid #fff;
                        }
                    }
                    &.active > a {
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }
        &.header {
            .nav > ul > li {
                > a {
                    &:hover {
                        color: #fff;
                    }
                }
                &:nth-of-type(-n+3) {
                    > a {
                        color: #fff;
                        &:hover {
                            color: #fff;
                            border-bottom: 2px solid #fff;
                        }
                    }
                    &.active > a {
                        color: #fff;
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }
    }
}
/* header */