/* page content */
.page {
    padding-top: 133px;
    padding-bottom: 90px;
	.news1, .news {
		.date {
			color: #509002;
		}
	}
	.page_nav {
		.active {
			color: #509002;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #509002;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #509002;
        &:hover {
            text-decoration: underline;
        }
    }
    @media screen and (max-width: 1280px) {
        padding-bottom: 50px;
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 1024px) {
        padding-top: 0;
        padding-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 20px;
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    background-color: #f0f2f5;
	font-size: 16px;
    margin-bottom: 55px;
	ul {
		@include ul-default;
		li {
			padding: 9px 0px;
			color: #509002;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '\f105';
                font-family: 'fontAwesome';
				margin: 3px 2px 0px 7px;
				color: #292929;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 1280px) {
        font-size: 14px;
        margin-bottom: 30px;
        ul li {
            a {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 25px;
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about {
        margin-top: -55px;
        padding-top: 55px;
        padding-bottom: 70px;
        h1 {
            margin-bottom: 35px;
        }
    }
    @media screen and (max-width: 1280px) {
        .about {
            margin-top: -30px;
            padding-top: 30px;
            padding-bottom: 50px;
            h1 {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .about {
            padding-bottom: 30px;
            margin-top: -25px;
        }
    }
}
.partners {
    background-color: #f0f2f5;
    padding: 90px 0 90px;
    margin-bottom: -90px;
    @media screen and (max-width: 1280px) {
        padding: 50px 0;
        margin-bottom: -50px;
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 0;
        margin-bottom: -30px;
    }
    @media screen and (max-width: 768px) {
        padding: 30px 0 40px;
        margin-bottom: -20px;
    }
}
.partners__slider {
    background-color: #ffffff;
    padding: 70px 70px 100px;
    margin-bottom: 0!important;
    .slick-slide {
        display: flex;
        flex-direction: column;
        outline: none;
        .partners__img {
            display: flex;
            height: 140px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
    }
    &.dots {
        .slick-dots {
            bottom: 45px;
        }
    }
    @media screen and (max-width: 1280px) {
        padding: 50px 50px 70px;
        &.dots {
            .slick-dots {
                bottom: 30px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 30px 30px 50px;
        .slick-slide {
            .partners__img {
                padding: 15px;
            }
        }
        &.dots {
            .slick-dots {
                bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 580px) {
        padding: 15px 15px 40px;
        .slick-slide {
            .partners__img {
                height: 100px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
            .partners__img {
                padding: 10px;
                height: 80px;
            }
        }
    }
}
.dots {
    .slick-dots {
        left: 0;
        bottom: 0;
        li {
            width: auto;
            height: auto;
            margin: 0 5px;
            button {
                width: 10px;
                height: 10px;
                background-color: #dddee1;
                border-radius: 100%;
                padding: 0;
                transition: 300ms;
                &:before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background-color: #508f00;
                }
            }
        }
    }
}
/* page about */

/* page catalog */
.cat__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .cat__item {
        width: calc(33.33% - 20px);
        margin: 0 10px 30px 10px;
    }
    @media screen and (max-width: 1024px) {
        .cat__item {
            .cat__item--img {
                height: 160px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__item {
            width: calc(50% - 10px);
            margin: 0 5px 20px;
            transform: scale(1)!important;
            .cat__item--img {
                height: 130px;
            }
            h3 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat__item {
            width: 100%;
            .cat__item--img {
                height: auto;
                img {
                    height: auto;
                }
            }
            h3 {
                min-height: 0px;
                padding: 15px 10px;
            }
        }
    }
}
.cat--unit {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    .left, .right {
        width: 50%;
    }
    .left {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    .right {
        padding-left: 45px;
    }
    .cat--unit__block {
        width: 555px;
    }
    h1 {
        font-size: 30px;
        text-align: left;
        margin-bottom: 35px;
    }
    .btn {
        padding-left: 55px!important;
        padding-right: 55px!important;
        margin-bottom: 35px;
    }
    .circle--list {
        li {
            padding-left: 0;
            &:before {
                left: -17px;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        h1 {
            font-size: 26px;
            margin-bottom: 25px;
        }
    }
    @media screen and (max-width: 1170px) {
        padding: 0 15px;
        .cat--unit__block {
            width: 100%;
        }
        .right {
            padding-left: 20px;
        }
        .circle--list {
            li {
                padding-left: 20px;
                &:before {
                    left: 0;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        h1 {
            font-size: 22px;
        }
        .btn {
            padding-left: 30px!important;
            padding-right: 30px!important;
            margin: 0 0 20px 0;
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
    @media screen and (max-width: 680px) {
        flex-wrap: wrap;
        .right, .left {
            width: 100%;
        }
        .left {
            margin-bottom: 30px;
        }
        .right {
            padding-left: 0;
        }
        h1 {
            font-size: 20px;
        }
    }
}
.cat--unit__for {
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    overflow: hidden;
    transition: 300ms;
    opacity: 0;
    &.slick-initialized {
        opacity: 1;
    }
    .slick-slide {
        height: 400px;
        outline: none;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    @media screen and (max-width: 1170px) {
        border-radius: 8px;
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 300px;
            border-radius: 8px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 250px;
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 200px;
        }
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
            height: 175px;
        }
    }
}
.cat--unit__nav {
    width: 575px;
    padding: 0 10px;
    margin-top: -45px;
    .slick-slide {
        height: 90px;
        overflow: hidden;
        border-radius: 5px;
        margin: 0 8px;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            transition: 300ms;
            opacity: 0.7;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &.slick-current {
            &:after {
                opacity: 0;
            }
        }
        &:hover:after {
            opacity: 0;
        }
    }
    &.arrow {
        .slick-arrow {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            opacity: 0.8;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            &:after {
                background-size: 10px;
            }
            &.slick-prev {
                left: 0px;
            }
            &.slick-next {
                right: 0px;
            }
        }
    }
    @media screen and (max-width: 1170px) {
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        margin-top: -35px;
        .slick-slide {
            height: 70px;
        }
    }
    @media screen and (max-width: 400px) {
        margin-top: -30px;
        .slick-slide {
            height: 60px;
        }
    }
}
.pagination {
    margin-top: 20px;
    ul {
        @include ul-default;
        display: block;
        text-align: center;
        li {
            @include inline-block;
            margin: 0 5px 5px;
            a {
                display: flex;
                width: 40px;
                height: 40px;
                font-family: $regularR;
                font-size: 24px;
                color: #333333;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-radius: 100%;
                box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
                &:hover {
                    background-color: #6a9533;
                    color: #fff;
                }
            }
            &.active {
                a {
                    background-color: #6a9533;
                    color: #fff;
                }
            }
            &.prev, &.next {
                a {
                    position: relative;
                    &:after {
                        font-family: 'fontAwesome';
                        font-size: 24px;
                        color: #333;
                        margin-top: -3px;
                        transition: 300ms;
                    }
                    &:hover {
                        &:after {
                            color: #fff;
                        }
                    }
                }
            }
            &.next {
                a:after {
                    content: '\f101';
                }
            }
            &.prev {
                a:after {
                    content: '\f100';
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        ul li {
            a {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ul li {
            margin: 0 3px 5px;
            a {
                width: 35px;
                height: 35px;
                font-size: 16px;
            }
            &.prev,
            &.next {
                a {
                    &:after {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
/* page catalog */

/* page contacts */
.map {
    height: 350px;
    overflow: hidden;
    margin-bottom: 60px;
    margin-top: -55px;
    @media screen and (max-width: 1280px) {
        height: 300px;
        margin-top: -30px;
        margin-bottom: 40px;
        > ymaps, .ymaps-2-1-73-map {
            height: 100%!important;
        } 
    }
    @media screen and (max-width: 1024px) {
        height: 250px;
        margin-top: -25px;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
        height: 200px;
        margin-top: -25px;
    }
}
.cts {
    display: flex;
    align-items: flex-start;
    h1 {
        width: 100%;
        text-align: left;
    }
    h4 {
        margin: 0 0 18px;
    }
    .left, .right {
        width: 50%;
    }
    .left {
        display: flex;
        flex-wrap: wrap;
    }
    .cts__col {
        padding-right: 20px;
        &:nth-of-type(1) {
            width: 315px;
        }
    }
    .col--location {
        margin-bottom: 30px;
    }
    ul {
        @include ul-default;
        li {
            margin-bottom: 22px;
            a {
                display: table;
            }
        }
    }
    .cts__form {
        background-color: #f0f2f5;
        padding: 75px 40px 60px;
    }
    .g-recaptcha {
        margin-bottom: 15px;
    }
    .btn {
        padding-left: 102px!important;
        padding-right: 102px!important;
    }
    @media screen and (max-width: 1280px) {
        .cts__col {
            width: 50%;
            &:nth-of-type(1) {
                width: 50%;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .cts__form {
            padding: 50px 30px 40px;
        }
    }
    @media screen and (max-width: 768px) {
        .cts__form {
            padding: 20px 20px 20px;
        }
        .cts__col {
            width: 100%;
            &:nth-of-type(1) {
                width: 100%;
            }
        }
        h4 {
            margin-bottom: 10px;
        }
        .col--location, 
        .col--phone,
        .col--email {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        .cts__col {
            padding-right: 0;
        }
        .left, .right {
            width: 100%;
        }
        .cts__form {
            .g-recaptcha {
                @include inline-block;
            }
            .btn {
                float: right;
                padding-left: 40px!important;
                padding-right: 40px!important;
                margin-top: 30px;
            }
        }
    }
    @media screen and (max-width: 540px) {
        .cts__form {
            padding-bottom: 150px;
            .g-recaptcha {
                float: right;
            }
            .btn {
                margin-top: 0px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .cts__form {
            .g-recaptcha {
                transform: scale(0.82);
                transform-origin: 100% 0;
            }
            .btn {
                margin-top: -10px;
            }
        }
    }
}
/* page contacts */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 50px;
            background-color: #fff;
            border-radius: 3px;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            color: #333333;
            border: 1px solid #e6e6e6;
            padding: 15px 30px;
            margin-bottom: 15px;
            box-shadow: none;
            &::placeholder {
                color: #949494;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1024px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
        }
    }
}

.btn--up {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    position: fixed;
    font-family: 'fontAwesome';
    font-size: 30px;
    color: #fff;
    bottom: 75px;
    right: 50px;
    background: linear-gradient(to top, #4d8107, #b6cd5c);
    border-radius: 100%;
    opacity: 0;
    z-index: 1;
    &:after {
        content: '\f0d8';
        line-height: 1px;
        margin-top: -5px;
    }
    &:hover, &:focus {
        color: #fff;
    }
    &:hover {
        transform: scale(1.1);
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

/* modal */

/* page content */