/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
    opacity: 0;
    transition: 1000ms;
    visibility: hidden;
    &.slick-initialized {
        opacity: 1;
        visibility: visible;
    }
	.slick-slide {
        display: flex;
        justify-content: flex-end;
        height: 100vh;
		overflow: hidden;
		position: relative;
		outline: none;
        transition: 300ms;
        &.slick-current {
            .gr {
                transform: scale(1.1);
                transition: 300ms;
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: -15vh;
            left: 50%;
            bottom: -15vh;
            right: 0;
            background-color: #fff;
            border-radius: 50% 0px 0px 50%;
            box-shadow: 0px 0px 25px rgba(0,0,0,0.3);
            transition: 500ms;
            opacity: 1;
        }
        div {
            transition: 0ms;
        }
		img {
			display: block;
			height: auto;
		}
		.slider__block {
            width: 500px;
			position: absolute;
			left: 50%;
			top: 50%;
            font-size: 20px;
			color: #fff;
            margin-left: -570px;
		    transform: translateY(-50%);
            .slider__title {
                display: block;
                font-family: $boldR;
                font-size: 60px;
                text-transform: uppercase;
                margin-bottom: 15px;
            }
            .btn {
                background-color: #fff!important;
                color: #292929!important;
                border: 2px solid #fff;
                margin-top: 60px;
                &:hover {
                    background-color: #292929!important;
                    border: 2px solid #292929;
                    color: #fff!important;
                }
            }
        }
        .slider__img {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 100% 0 0 100%;
            padding: 50px 100px;
            position: relative;
            z-index: 1;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        .bg--text__title {
            font-size: 28vw;
            text-transform: initial;
            right: 50%;
            top: 46%;
            line-height: 370px;
        }
	}
	.slick-dots {
        display: flex;
        width: auto;
		bottom: 100px;
        left: 50%;
        margin-left: -570px;
		li {
			width: auto;
            height: auto;
            margin: 0;
            a {
                display: flex;
                justify-content: center;
                height: 50px;
                position: relative;
                font-family: $regularR;
                font-size: 24px;
                color: #aec68a;
                padding: 3px 18px 20px;
                line-height: 1;
                border-bottom: 2px solid #aec589;
                &:after {
                    content: '';
                    height: 4px;
                    background-color: #ffffff;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -2px;
                    transition: 300ms;
                    opacity: 0;
                }
            }
            &.slick-active {
                a {
                    font-size: 30px;
                    color: #fefefe;
                    padding-left: 0;
                    padding-top: 0;
                    &:after {
                        opacity: 1;
                    }
                }
            }
		}
	}
    @media screen and (max-width: 1440px) {
        .slick-slide {
            &:after {
                width: 48%;
                left: 53%;
            }
            .slider__img {
                width: 48%;
            }
            .slider__block {
                font-size: 18px;
                .slider__title {
                    font-size: 45px;
                }
                .btn {
                    margin-top: 30px;
                }
            }
        }
        .slick-dots {
            bottom: 50px;
        }
    }
    @media screen and (max-width: 1366px) {
        .slick-dots {
            li {
                a {
                    font-size: 20px;
                    height: 40px;
                    padding: 3px 12px 10px;
                }
                &.slick-active {
                    a {
                        font-size: 24px;
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .slick-slide {
            &:after {
                width: 45%;
                left: 55%;
            }
            .slider__img {
                width: 45%;
            }
        }
    }
    @media screen and (max-width: 1170px) {
        .slick-slide {
            .slider__block {
                left: 15px;
                margin: 0;
            }
        }
        .slick-dots {
            left: 15px;
            margin: 0;
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 400px;
            &:after {
                left: 60%;
                width: 40%;
            }
            .slider__block {
                width: 55%;
                .slider__title {
                    font-size: 35px;
                }
            }
            .slider__img {
                width: 40%;
                padding: 30px 60px;
            }
        }
        .slick-dots {
            justify-content: center;
            left: 15px;
            right: 15px;
            bottom: 25px;
            li {
                a {
                    height: 32px;
                    font-size: 18px;
                    padding: 3px 8px 5px;
                }
                &.slick-active {
                    a {
                        font-size: 22px;
                        padding-left: 8px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .bg--text__title {
            display: none;
        }
        .slick-slide {
            height: 350px;
            &.bg--text {
                background: #fff;
            }
            &:after {
                display: none;
            }
            .slider__block {
                width: auto;
                font-size: 16px;
                text-align: center;
                position: absolute;
                left: 15px;
                right: 15px;
                background-color: rgba(0,0,0,0.4);
                padding: 20px;
                z-index: 1;
                .slider__title {
                    font-size: 30px;
                    margin-bottom: 10px;
                }
                .btn {
                    background-color: #508f00!important;
                    color: #fff!important;
                    border: 2px solid #508f00;
                    margin: 0 auto;
                }
            }
            .slider__img {
                width: auto;
                padding: 25px 20px;
                border-radius: 0px;
                margin: 0 auto;
            }
        }
        .slick-dots {
            bottom: 10px;
            li {
                &.slick-active {
                    a {
                        color: #467c01;
                        &:after {
                            background-color: #467c01;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 680px) {
        .slick-slide {
            .slider__block {
                .slider__title {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 580px) {
        .slick-slide {
            .slider__block {
                font-size: 14px;
                .slider__title {
                    font-size: 18px;
                }
            }
        }
    }
}
/* main slider */

/* main about */
.title--g {
    color: #cccccc;
    text-align: center;
    margin: 0 0 30px;
    @media screen and (max-width: 1280px) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 10px;
    }
}
.title--gr {
    color: #508f00;
    text-align: center;
}
.section--img {
    position: relative;
    overflow: hidden;
    > img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        z-index: -1;
    }
}
.about {
    > img {
        object-position: left top;
        opacity: 0.5;
    }
    @media screen and (max-width: 1280px) {
        > img {
            opacity: 0.3;
        }
        .title--g {
            color: #757575;
        }
    }
}
.about__block {
    display: flex;
    .about__col {
        width: 50%;
        padding-right: 25px;
        &:nth-of-type(even) {
            padding-right: 0;
        }
    }
    @media screen and (max-width: 680px) {
        flex-wrap: wrap;
        .about__col {
            width: 100%;
            padding-right: 0;
        }
    }
}
/* main about */

/* main advantages */
.bg--text {
    position: relative;
    overflow: hidden;
    .bg--text__title {
        font-family: $boldR;
        font-size: 8.2vw;
        color: #fff;
        text-transform: uppercase;
        opacity: 0.1;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        white-space: nowrap;
        line-height: 1;
        pointer-events: none;
    }
}
.advantages {
    .container {
        padding: 60px 15px;
    }
    @media screen and (max-width: 1024px) {
        .container {
            padding: 40px 15px;
        }
    }
    @media screen and (max-width: 768px) {
        .container {
            padding: 30px 15px;
        }
    }
    @media screen and (max-width: 540px) {
        .container {
            padding-top: 10px;
        }
        .bg--text__title {
            display: block;
            width: 100%;
            font-size: 24px;
            opacity: 1;
            text-align: center;
            position: relative;
            transform: translate(0);
            pointer-events: auto;
            margin-top: 35px;
        }
    }
}
.advantages__block {
    display: flex;
    justify-content: space-between;
    .advantages__item {
        max-width: 33.33%;
        text-align: center;
        padding: 0 25px;
        img {
            width: 60px;
            height: 60px;
            object-fit: contain;
            object-position: center center;
        }
        h3 {
            color: #fff;
            margin: 25px 0 0;
        }
    }
    @media screen and (max-width: 1024px) {
        .advantages__item {
            padding: 0 15px;
            h3 {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 540px) {
        flex-direction: column;
        align-items: center;
        .advantages__item {
            max-width: 100%;
            padding: 0;
            margin: 20px 0;
        }
    }
}
/* main advantages */

/* main services */
.services {
    padding-bottom: 70px;
    .container {
        padding-bottom: 0;
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 40px;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 20px;
    }
    @media screen and (max-width: 600px) {
        padding-bottom: 0;
    }
}
.more {
    display: table;
    font-family: $boldS;
    font-size: 16px;
    text-transform: uppercase;
    color: #509002;
    position: relative;
    padding-right: 22px;
    &:after {
        content: '\f101';
        font-family: 'fontAwesome';
        font-size: 18px;
        color: #509002;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: 300ms;
    }
    &:hover:after {
        right: -5px;
    }
}
.services__block {
    .row {
        display: flex;
        margin: 0 0 60px 0;
        .services__item {
            display: flex;
            width: 50%;
            padding-right: 15px;
            justify-content: flex-end;
            .services__info {
                width: 555px;
                position: relative;
                padding-bottom: 30px;
                .more {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
                h3 {
                    margin: 0 0 25px 0;
                }
            }
        }
        .services__img {
            width: calc(50% - 15px);
            border-radius: 8px 0px 0px 8px;
            overflow: hidden;
            position: relative;
            margin-left: 15px;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: inset 0px 0px 25px rgba(0,0,0,0.5);
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        &:nth-child(even) {
            .services__img {
                order: 1;
                border-radius: 0px 8px 8px 0px;
                margin: 0 15px 0 0;
            }
            .services__item {
                justify-content: flex-start;
                order: 2;
                padding: 0 0 0 15px;
            }
        }
    }
    @media screen and (max-width: 1170px) {
        .row {
            margin-bottom: 30px;
            .services__item {
                padding-left: 15px;
                .services__info {
                    width: 100%;
                    h3 {
                        margin: 0 0 10px 0;
                    }
                }
            }
            &:nth-of-type(even) {
                .services__item {
                    padding-right: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        padding: 0 15px;
        .row {
            flex-wrap: wrap;
            margin-bottom: 35px;    
            .services__item {
                width: 100%;
                padding: 0;
                .services__info {
                    padding-bottom: 15px;
                    .more {
                        position: relative;
                        bottom: 0;
                    }
                }
            }
            .services__img {
                width: 100%;
                height: auto;
                border-radius: 8px;
                margin: 0;
                img {
                    height: auto;
                }
            }
            &:nth-child(even) {
                .services__img {
                    border-radius: 8px;
                    margin: 0;
                    order: 2;
                }
                .services__item {
                    padding: 0;
                    order: 1;
                }
            }
        }
    }
}
/* main services */

/* main catalog */
.cat {
    padding-bottom: 85px;
    .container {
        padding-bottom: 0;
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
    }
}
.cat__slider {
    margin: -20px -15px;
    .slick-track {
        padding: 25px 0!important;
        .slick-active,
        .slick-active + .slick-active + .slick-active + .slick-active + .slick-active {
            opacity: 0.5;
        }
        .slick-active + .slick-active,
        .slick-active + .slick-active + .slick-active,
        .slick-active + .slick-active + .slick-active {
            opacity: 1;
        }
    }
    .slick-slide {
        outline: none;
        margin: 0 15px;
    }
    .cat__item {
        background-color: #fff;
    }
    &.arrow {
        .slick-arrow {
            opacity: 0.8;
            &.slick-prev {
                left: 50%;
                margin-left: -605px;
            }
            &.slick-next {
                right: 50%;
                margin-right: -605px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .cat__item {
            h3 {
                font-size: 22px;
            }
            .cat__item--img {
                height: 160px;
            }
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    margin-left: -455px;
                }
                &.slick-next {
                    margin-right: -455px;
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        margin: 0;
        .slick-active,
        .slick-active + .slick-active + .slick-active + .slick-active {
            opacity: 0.5;
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    left: 15px;
                    margin: 0;
                }
                &.slick-next {
                    right: 15px;
                    margin: 0;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-track { 
            padding: 0!important;
        }
        .slick-active {
            opacity: 1!important;
        }
        .cat__item {
            transform: scale(1)!important;
            h3 {
                font-size: 20px;
            }
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    left: 5px;
                }
                &.slick-next {
                    right: 5px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__item {
            h3 {
                font-size: 18px;
            }
        }
    }
}
.arrow {
    .slick-arrow {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: #fff;
        transition: 300ms;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url(../img/arrowLeft.png) no-repeat center center;
            background-size: 15px;
        }
        &.slick-prev {
            left: 0;
        }
        &.slick-next {
            right: 0;
            &:after {
                transform: rotate(-180deg);
            }
        }
        &:hover {
            background-color: #457a00;
            &:after {
                background: url(../img/arrowLeftW.png) no-repeat center center;
                background-size: 15px;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .slick-arrow {
            width: 40px;
            height: 40px;
            &:after {
                background-size: 10px;
            }
            &:hover:after {
                background-size: 10px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-arrow {
            width: 35px;
            height: 35px;
            &:after {
                background-size: 8px;
            }
            &:hover:after {
                background-size: 8px;
            }
        }
    }
}
.cat__item {
    background-color: #f0f2f5;
    .cat__item--img {
        height: 220px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        min-height: 80px;
        padding: 5px 10px;
        margin: 0;
        transition: 300ms;
    }
    &:hover {
        box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
        transform: scale(1.1);
        h3 {
            color: #457a00;
        }
    }
    @media screen and (max-width: 1024px) {
        h3 {
            font-size: 16px;
        }
    }
}
/* main catalog */

/* main news */
.news__block {
    display: flex;
    justify-content: space-between;
    .left, .right {
        display: flex;
        width: calc(50% - 15px);
        flex-wrap: wrap;
        align-items: stretch;
    }
    .news__item {
        width: 100%;
        background-color: #f0f2f5;
        .news__img {
            height: 210px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .news__info {
            padding: 40px 20px 30px;
            color: #333333;
            .date {
                display: block;
                color: #6b6c6d;
                margin-bottom: 22px;
            }
            h3 {
                color: #333333;
                transition: 300ms;
                margin: 0 0 15px 0;
            }
        }
        &:hover {
            transform: scale(1.05);
            .news__info {
                h3 {
                    color: #457b01;
                }
            }
        }
    }
    .right {
        flex-direction: column;
        justify-content: space-between;
        .news__item {
            display: flex;
            height: 210px;
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0px; 
            }
            .news__img {
                width: 210px;
            }
            .news__info {
                width: calc(100% - 210px);
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .news__item {
            .news__info {
                padding: 20px;
                h3 {
                    font-size: 18px;
                }
                .date {
                    margin-bottom: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .news__item {
            .news__info {
                h3 {
                    font-size: 16px;
                }
            }
        }
        .right {
            .news__item {
                .news__img {
                    width: 150px;
                }
                .news__info {
                    width: calc(100% - 150px);
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        .news__item {
            height: auto;
            margin-bottom: 25px;
            .news__img {
                height: auto;
            }
        }
        .left, .right {
            width: 100%;
        }
        .right {
            justify-content: flex-start;
            .news__item {
                height: auto;
                margin-bottom: 25px;
                &:last-of-type {
                    margin-bottom: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .right {
            .news__item {
                flex-wrap: wrap;
                height: auto;
                .news__img {
                    width: 100%;
                }
                .news__info {
                    width: 100%;
                }
            }
        }
    }
}
/* main news */

/* main content */